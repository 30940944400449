import React, { useEffect, useState, updateState } from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import './style.scss';
import { useNavigate } from 'react-router-dom';
import CloseCard from '../../../assets/images/CloseTask.svg';
import DeleteSVG from '../../../assets/images/DeleteSVG.svg';
import EditSVG from '../../../assets/images/EditSVG.svg';
import ViewDetails from '../../../assets/images/view.svg';
import DeleteSubCategory from '../../../assets/images/DeleteSubCategory.svg';
import { Input, View, Button, Card, InvesteeSidebar, Textbox, Textarea, SelectTag, CustomSelect, Calender } from '../../../components';
import { FaDivide } from 'react-icons/fa';
var mobile = require('is-mobile');
const Metrics = observer(() => {
    const store = useStore();
    const navigate = useNavigate();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [Company, setCompany] = useState({});
    const [Actuals, setActuals] = useState([]);
    const [Forecast, setForecast] = useState([]);
    const [MetricsData, setMetricsData] = useState([]);
    const [TabSelected, setTabSelected] = useState('Forecast');
    const [AddNewMetric, setAddNewMetric] = useState(false);
    const [NewMetricTemplate, setNewMetricTemplate] = useState({});
    const [AddMonth, setMonth] = useState(false);
    const [IsHovered, setIsHovered] = useState(false);
    const [CredsDate, setCredsDate] = useState(null);
    const [IsEditIncome, setISEditIncome] = useState(false);
    const [IsViewMetric, setIsViewMetric] = useState(false);
    const [UpdateMetric, setUpdateMetric] = useState(false);
    const [SelectedMetric, setSelectedMetric] = useState({
        NewMetric: {},
        OldMetric: {}
    });
    const [metrics, setMetrics] = useState({
        Burnrate: {
            Burn_Rate: [
                { Category: '', Amount: 0 },
            ],
        },

        Costs: {
            Cost_of_Sales: [
                { Category: '', Amount: 0 },
            ],
        },
        Expenses: {
            Operating_Expenses: [
                { Category: '', Amount: 0 },
            ],
        },
        Income: {
            Income: [
                { Category: '', Amount: 0 },
            ],
        },
        Cashflow: {
            Cash_Flow: [
                { Category: '', Amount: 0 },
            ],
        },
        Date: new Date(),
        Type: "",
    });
    const [newSubCategory, setNewSubCategory] = useState('');
    const [newAmount, setNewAmount] = useState('');
    const [burnRate, setBurnRate] = useState(0);
    useEffect(() => {
        getMetrics();
    }, []);
    const getMetrics = async () => {
        try {
            store.setLoading(true);
            let acts = [];
            let fcs = [];
            let comp = await store.getCompaniesByPerms();
            if (!comp) {
                store.setLoading(false);
                window.alert('Error fetching company.')
                return;
            }
            let updComps = comp.filter(item => item.Name === store.user.Company);
            setCompany(updComps[0]);
            let values = updComps[0].Values;
            setMetricsData(values)
            for (let i = 0; i < values.length; i++) {
                let item = values[i];
                if (item.Type === 'Forecast') {
                    fcs.push(item);
                }
                if (item.Type === 'Actual') {
                    acts.push(item);
                }
            }
            setNewMetricTemplate(acts[acts.length - 1]);
            setActuals(acts);
            setForecast(fcs);
            forceUpdate();
            store.setLoading(false);
        } catch (e) {
            console.error(e);
        }
    }
    const changeTabSelected = (e) => {
        setTabSelected(e.target.id);
        forceUpdate();
    }
    const addNewMetricClicked = () => {
        setAddNewMetric(true);
        forceUpdate();
    }
    const closeAddMetric = () => {
        setAddNewMetric(false);
        setUpdateMetric(false);
        forceUpdate();
    }
    const handleHoverStart = () => {
        setIsHovered(true);
        forceUpdate();
    };
    const handleHoverEnd = () => {
        setIsHovered(false);
        forceUpdate();
    };
    const addMonth = () => {
        setMonth(true);
        forceUpdate();
    }
    const closeMonth = () => {
        setMonth(false);
        forceUpdate();
    }
    const getDate = (date) => {
        setCredsDate(date);
        forceUpdate();
    };
    const handleChange = (e, category) => {
        const value = parseFloat(e.target.value) || 0;
        setMetrics(prevMetrics => ({
            ...prevMetrics,
            [category]: {
                ...prevMetrics[category],
                [Object.keys(prevMetrics[category])[0]]: prevMetrics[category][Object.keys(prevMetrics[category])[0]].map(item => ({
                    ...item,
                    Amount: value,
                }))
            }
        }));
        calculateBurnRate();
        forceUpdate();
    };
    const handleInputChange = (metricKey, subCategory, index, field, newValue) => {
        setMetrics(prevMetrics => ({
            ...prevMetrics,
            [metricKey]: {
                ...prevMetrics[metricKey],
                [subCategory]: prevMetrics[metricKey][subCategory].map((item, idx) =>
                    idx === index ? { ...item, [field]: newValue } : item
                ),
            },
        }));

    };
    const handleAddSubCategory = (metricKey, subCategory) => {
        if (newSubCategory && newAmount) {
            setMetrics(prevMetrics => ({
                ...prevMetrics,
                [metricKey]: {
                    ...prevMetrics[metricKey],
                    [subCategory]: [
                        ...prevMetrics[metricKey][subCategory],
                        { Category: newSubCategory, Amount: parseFloat(newAmount) },
                    ],
                },
            }));
            setNewSubCategory('');
            setNewAmount('');
        }
    };
    const handleDeleteSubCategory = (metricKey, subCategory, index) => {
        setMetrics(prevMetrics => ({
            ...prevMetrics,
            [metricKey]: {
                ...prevMetrics[metricKey],
                [subCategory]: prevMetrics[metricKey][subCategory].filter((_, idx) => idx !== index),
            },
        }));
    };
    const handleAddCategory = (metricKey, subCategory) => {
        if (newSubCategory) {
            setMetrics(prevMetrics => ({
                ...prevMetrics,
                [metricKey]: {
                    ...prevMetrics[metricKey],
                    [newSubCategory]: [{ Category: '', Amount: 0 }],
                },
            }));
            setNewSubCategory('');
        }
    };
    const handleDeleteCategory = (metricKey, subCategory) => {
        const updatedMetrics = { ...metrics };
        delete updatedMetrics[metricKey][subCategory];
        setMetrics(updatedMetrics);
    };
    function convertToISODate(dateString) {
        const [monthName, year] = dateString.split(' ');
        const month = new Date(`${monthName} 1, ${year}`).getMonth() + 1;
        const isoDateString = new Date(`${year}-${month.toString().padStart(2, '0')}-01T22:00:00.000Z`).toISOString();
        return isoDateString;
    }
    function convertToISOFromActDate(actDateString) {
        const dateObject = new Date(actDateString);
        const isoDateString = dateObject.toISOString();
        return isoDateString;
    }
    const saveMeterics = async () => {
        store.setLoading(true);
        try {
            let compId = Company._id;
            let data = Company;
            let newOn = metrics;
            let values = data.Values;

            if (newOn.Type === 'Forecast') {
                let date = convertToISODate(CredsDate)
                newOn.Date = date;
            } else if (newOn.Type === 'Actual') {
                let actDate = new Date()
                let finalDate = convertToISOFromActDate(actDate)
                newOn.Date = finalDate;
            }
            let found = false;

            values.forEach((item, index) => {
                const itemDate = new Date(item.Date);
                const newOnDate = new Date(newOn.Date);

                if (item.Type === 'Actual' && itemDate.getFullYear() === newOnDate.getFullYear() && itemDate.getMonth() === newOnDate.getMonth()) {
                    values.splice(index, 1);
                    values.push(newOn);
                    found = true;
                }
            });
            if (!found) {
                values.push(newOn);
            }
            data.Values = values;
            let newData = {
                Values: values
            }
            let update = await store.updateCompanyById(compId, newData);
            if (!update) {
                window.alert('Error saving metrics.');
                store.setLoading(false);
                return;
            }
            console.log('update', update)
            window.alert('Metrics saved successfully.');
            forceUpdate();
            store.setLoading(false);
        } catch (e) {
            console.error(e);
            store.setLoading(false);
            return
        }
        forceUpdate();
    };
    const typeChange = (e) => {
        let item = metrics;
        item.Type = e.target.id;
        setMetrics(item);
        forceUpdate();
    }
    const calculateBurnRate = () => {
        const expenses = metrics.Expenses.Operating_Expenses.reduce((acc, item) => acc + item.Amount, 0);
        const income = metrics.Income.Income.reduce((acc, item) => acc + item.Amount, 0);
        const cashflow = metrics.Cashflow.Cash_Flow.reduce((acc, item) => acc + item.Amount, 0);

        const burnRateValue = cashflow !== 0 ? (expenses - income) / cashflow : 0;
        setMetrics(prevMetrics => ({
            ...prevMetrics,
            Burnrate: {
                Burn_Rate: prevMetrics.Burnrate.Burn_Rate.map(item => ({
                    ...item,
                    Amount: burnRateValue,
                }))
            }
        }));
        setBurnRate(burnRateValue);
        forceUpdate();
    };
    function formatDateToMonthYear(dateString) {
        const date = new Date(dateString);
        const options = { year: 'numeric', month: 'long' };
        return date.toLocaleDateString('en-US', options);
    };
    const handleDeleteMetric = async (item) => {
        try {
            let comfirmed = window.confirm(`Are you sure you want to delete the ${item.Type} metric from ${formatDateToMonthYear(item.Date)}?`);
            store.setLoading(true);
            if (!comfirmed) {
                store.setLoading(false);
                return
            } else {
                let values = MetricsData;
                const index = values.findIndex((metric) =>
                    JSON.stringify(metric) === JSON.stringify(item)
                );
                if (index !== -1) {
                    values.splice(index, 1);
                }

                let compId = Company._id;
                let data = {
                    Values: values
                };
                let update = await store.updateCompanyById(compId, data);
                if (!update) {
                    alert('Error deleting metric.');
                    store.setLoading(false);
                    return;
                }
                setMetricsData(values);
                forceUpdate();
                store.setLoading(false);
            }
        } catch (error) {
            console.error('Error deleting metric');
            store.setLoading(false);
            return;
        }
    };
    function sumAllValues(values) {
        let total = 0;

        for (const key in values) {
            if (Array.isArray(values[key])) {
                total += values[key].reduce((sum, item) => sum + (item.Amount || 0), 0);
            }
        }

        return total;
    }
    const handleUpdateMetric = (item) => {
        let totalIncome = sumAllValues(item.Income);
        let totalExpenses = sumAllValues(item.Expenses);
        let totalCashflow = sumAllValues(item.Cashflow)
        let totalBurnRate = sumAllValues(item.Burnrate)
        let date = item.Date;
        let type = item.Type;
        let newObj = {
            Income: totalIncome,
            Expenses: totalExpenses,
            Cashflow: totalCashflow,
            Burnrate: totalBurnRate,
            Date: date,
            Type: type,
        }
        let data = {
            OldMetric: item,
            NewMetric: newObj
        }
        console.log('Data:', data)
        setSelectedMetric(data);
        setUpdateMetric(true);
        forceUpdate();
    };
    const handleUpdateMetricValues = (e) => {
        let id = e.currentTarget.id;
        let newObj = SelectedMetric.NewMetric;
        if (id === 'Income') {
            newObj.Income = Number(e.target.value)
        }
        if (id === 'Expenses') {
            newObj.Expenses = Number(e.target.value)
        }
        if (id === 'Burnrate') {
            newObj.Burnrate = Number(e.target.value)
        }
        if (id === 'Cashflow') {
            newObj.Cashflow = Number(e.target.value)
        }
        let data = {
            OldMetric: SelectedMetric.OldMetric,
            NewMetric: newObj
        }
        setSelectedMetric(data);
        forceUpdate();
    };
    const updateMetricClicked = async () => {
        store.setLoading(true);
        try {
            let values = SelectedMetric;
            let newObj = {
                Burnrate: {
                    Burn_Rate: [
                        { Category: '', Amount: values.NewMetric.Burnrate },
                    ],
                },

                Costs: {
                    Cost_of_Sales: [
                        { Category: '', Amount: 0 },
                    ],
                },
                Expenses: {
                    Operating_Expenses: [
                        { Category: '', Amount: values.NewMetric.Expenses },
                    ],
                },
                Income: {
                    Income: [
                        { Category: '', Amount: values.NewMetric.Income },
                    ],
                },
                Cashflow: {
                    Cash_Flow: [
                        { Category: '', Amount: values.NewMetric.Cashflow },
                    ],
                },
                Date: new Date(values.NewMetric.Date),
                Type: values.NewMetric.Type,
            }
            const updatedArray = MetricsData.map(item =>
                JSON.stringify(item) === JSON.stringify(values.OldMetric) ? newObj : item
            );
            let data = {
                Values: updatedArray
            }
            let update = await store.updateCompanyById(Company._id, data);
            if (!update) {
                alert('Error updating metric.');
                store.setLoading(false);
                return;
            }
            setMetricsData(updatedArray);
            setUpdateMetric(false);
            forceUpdate();
            store.setLoading(false);
        } catch (e) {
            console.error('Error updating metric', e);
            alert('Error updating metrics.')
            store.setLoading(false);
            return
        }
    }
    if (mobile()) {
        return (
            <View >
            </View>
        );
    } else {
        return (
            <div className='metricsHomepage'>
                <div className='addnewMetricsSection'>
                    <div className='addNewMetricSect'>
                        <button className='addNewMetricBtn' onClick={addNewMetricClicked}>New Metric</button>
                    </div>
                    <div className='viewMetricByTypeSect'>
                        <div className='forecastTab' id='Forecast' onClick={changeTabSelected} style={{ backgroundColor: TabSelected === 'Forecast' ? '#213038' : '#ffffff', color: TabSelected === 'Forecast' ? '#fff' : '#213038' }}>
                            <p className='typeName' id='Forecast' onClick={changeTabSelected}>Forecast</p>
                            <p className='typeValue' id='Forecast' onClick={changeTabSelected}>{MetricsData.filter(item => item.Type === 'Forecast').length || 0}</p>
                        </div>
                        <div className='forecastTab' id='Actual' onClick={changeTabSelected} style={{ backgroundColor: TabSelected === 'Actual' ? '#213038' : '#ffffff', color: TabSelected === 'Actual' ? '#fff' : '#213038' }}>
                            <p className='typeName' id='Actual' onClick={changeTabSelected}>Actual</p>
                            <p className='typeValue' id='Actual' onClick={changeTabSelected}>{MetricsData.filter(item => item.Type === 'Actual').length || 0}</p>
                        </div>
                    </div>
                </div>
                <div className='availMetricsMapped'>
                    <div className='metricsHeaderSection'>
                        <p className='typeMetricSec'>Type</p>
                        <p className='dateMetricSect'>Date</p>
                    </div>
                    <div className='metricsSec'>
                        {MetricsData.filter(item => item.Type === TabSelected).map((item, index) => {
                            return (
                                <div className='metircCard' key={index}>
                                    <div className='metricDetailsSect'>
                                        <p className='metricType'>{item.Type}</p>
                                        <p className='metricDate'>{formatDateToMonthYear(item.Date)}</p>
                                    </div>
                                    <div className='actionsSection'>
                                        <img src={EditSVG} alt="Edit" onClick={() => handleUpdateMetric(item)} />
                                        <img src={DeleteSVG} alt="Delete" onClick={() => handleDeleteMetric(item)} />
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                {(AddNewMetric) ?
                    <div className='addNewMetricCard'>
                        <img className='closeCardSect' src={CloseCard} onClick={closeAddMetric} />

                        <div className='dateAndTypeSect'>
                            <CustomSelect className='departmentInput' onMouseEnter={handleHoverStart} onMouseLeave={handleHoverEnd} Active={IsHovered} id='Type' placeholder='Type *' style={{ marginTop: 'unset' }} value={metrics.Type}>
                                <div value='Forecast' id='Forecast' onClick={typeChange} className={`${(metrics.Type === 'Forecast') ? 'selectActive' : 'customOptions'}`}>Forecast</div>
                                <div value='Actual' id='Actual' onClick={typeChange} className={`${(metrics.Type === 'Actual') ? 'selectActive' : 'customOptions'}`}>Actual</div>
                            </CustomSelect>
                            {(metrics.Type !== 'Actual') ?
                                <div className='changeStatusHover' onMouseEnter={addMonth} onMouseLeave={closeMonth}>
                                    <p className='placeHolder'>Month/Date</p>
                                    <p>{CredsDate || '00/00/0000'}</p>
                                    {(AddMonth) ?
                                        <div className='addCredsMonthSelector'>
                                            <Calender onClick={getDate} />
                                        </div> : null}
                                </div> : null}
                        </div>
                        <hr className='hr' style={{ width: '93%' }} />
                        <div className='incomeConatiner'>
                            <p>Income</p>
                            <input type='number' value={metrics.Income.Income[0].Amount} onChange={(e) => handleChange(e, 'Income')} />
                        </div>
                        <div className='incomeConatiner'>
                            <p>Expenses</p>
                            <input type='number' placeholder='R0.00' value={metrics.Expenses.Operating_Expenses[0].Amount} onChange={(e) => handleChange(e, 'Expenses')} />
                        </div>
                        <div className='incomeConatiner'>
                            <p>Cashflow</p>
                            <input type='number' placeholder='R0.00' value={metrics.Cashflow.Cash_Flow[0].Amount} onChange={(e) => handleChange(e, 'Cashflow')} />
                        </div>
                        <div className='incomeConatiner'>
                            <p>Burnrate</p><input value={burnRate.toFixed(2)} />
                        </div>
                        {/* <div className='categoryMetricsSection'>
                            {Object.keys(metrics).map(metricKey => (
                                typeof metrics[metricKey] === 'object' && metricKey !== 'Date' && metricKey !== 'Type' && (
                                    <div className='incomeMetricCard' key={metricKey} >
                                        <div className='metricNameSect'>
                                            <p>{metricKey}</p>
                                            <div className='totalAmountAndDropdown'>
                                                <p>R{Object.values(metrics[metricKey]).flat().reduce((acc, item) => acc + item.Amount, 0).toFixed(2)}</p>
                                                <img src={ViewDetails} />
                                            </div>
                                        </div>
                                        <hr className='hr' style={{ width: '98%' }} />
                                        <div className='metricCategoriesSect'>
                                            <div style={{ width: '100%', height: '90%', marginBottom: '1%', display: 'center', alignItems: 'center' }}>
                                                {Object.keys(metrics[metricKey]).map(subCategory => (
                                                    <div key={subCategory} className='testing'>
                                                        <div className='categoryHeaderSect'>
                                                            <p>{subCategory}</p>
                                                            <div className='totalAmountAndDropdown'>
                                                                <p>R{metrics[metricKey][subCategory].reduce((acc, item) => acc + item.Amount, 0).toFixed(2)}</p>
                                                                <img src={ViewDetails} />
                                                            </div>
                                                        </div>
                                                        {metrics[metricKey][subCategory].map((item, index) => (
                                                            <div className='subCategoryContainer' key={index}>
                                                                <input placeholder='Category' style={{ width: '15%', marginLeft: '5%' }} value={item.Category} onChange={e => handleInputChange(metricKey, subCategory, index, 'Category', e.target.value)} />
                                                                <div className='totalAmountAndDropdown'>
                                                                    <input placeholder='Amount' style={{ width: '75%' }} type='number' value={item.Amount} onChange={e => handleInputChange(metricKey, subCategory, index, 'Amount', e.target.value)} />
                                                                    <img src={DeleteSubCategory} onClick={() => handleDeleteSubCategory(metricKey, subCategory, index)} />
                                                                </div>
                                                            </div>
                                                        ))}
                                                        <button key={subCategory} onClick={() => handleAddSubCategory(metricKey, subCategory)}>New Sub-Category</button>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        <div className='subCategoryContainer' style={{width: '97%'}}>
                                            <input placeholder='Category' style={{ width: '15%' }} value={newSubCategory} onChange={e => setNewSubCategory(e.target.value)} />
                                            <div className='totalAmountAndDropdown'>
                                                <input placeholder='Amount' style={{ width: '75%' }} type='number' value={newAmount} onChange={e => setNewAmount(e.target.value)} />
                                                <img src={DeleteSubCategory} onClick={() => handleAddCategory(metricKey)} />
                                            </div>
                                        </div>
                                        <button onClick={() => handleAddCategory(metricKey)} style={{height: '11%', marginBottom: '1%'}}>New Category</button>
                                    </div>
                                )
                            ))}
                            <div className='incomeMetricCard'>
                                <div className='metricNameSect'>
                                    <p>Income </p><div className='totalAmountAndDropdown'><p>R10.00</p><img src={ViewDetails} /></div>
                                </div>
                                <hr className='hr' style={{ width: '98%' }} />
                                <div className='metricCategoriesSect'>
                                    <div className='subCatsContainer'>
                                        <div className='categoryHeaderSect'>
                                            <p>Category</p>
                                            <div className='totalAmountAndDropdown'><p>R10.00</p><img src={ViewDetails} /></div>
                                        </div>
                                        <div className='subCategoryContainer'>
                                            <input placeholder='Sub-Category' style={{ width: '15%', marginLeft: '5%' }} />
                                            <div className='totalAmountAndDropdown'>
                                                <input placeholder='Amount' style={{ width: '75%' }} />
                                                <img src={DeleteSubCategory} />
                                            </div>
                                        </div>
                                    </div>
                                    <button>New Sub-Category</button>
                                    <div className='subCategoryContainer'>
                                        <input placeholder='Category' style={{ width: '15%' }} />
                                        <div className='totalAmountAndDropdown'>
                                            <input placeholder='Amount' style={{ width: '75%' }} type='number' />
                                            <img src={DeleteSubCategory} />
                                        </div>
                                    </div>
                                    <button>New Category</button>
                                </div>
                            </div>
                        </div> */}
                        <div className='btnSection'>
                            <button className='saveBtn' onClick={saveMeterics}>Save Metric</button>
                        </div>
                    </div> : null}
                {(UpdateMetric) ?
                    <div className='addNewMetricCard'>
                        <img className='closeCardSect' src={CloseCard} onClick={closeAddMetric} />

                        <div className='dateAndTypeSect'>
                            <div className='monthTypeContainer'>
                                <p className='placeHolder'>Metric Type</p>
                                <p className='values'>{SelectedMetric.NewMetric.Type}</p>
                            </div>
                            <div className='monthTypeContainer'>
                                <p className='placeHolder'>Metric Date</p>
                                <p className='values'>{formatDateToMonthYear(SelectedMetric.NewMetric.Date)}</p>
                            </div>
                        </div>
                        <hr className='hr' style={{ width: '93%' }} />
                        <div className='incomeConatiner'>
                            <p>Income</p>
                            <input type='number' value={SelectedMetric.NewMetric.Income} id='Income' onChange={(e) => handleUpdateMetricValues(e, 'Income')} />
                        </div>
                        <div className='incomeConatiner'>
                            <p>Expenses</p>
                            <input type='number' value={SelectedMetric.NewMetric.Expenses} id='Expenses' onChange={(e) => handleUpdateMetricValues(e, 'Expenses')} />
                        </div>
                        <div className='incomeConatiner'>
                            <p>Cashflow</p>
                            <input type='number' value={SelectedMetric.NewMetric.Cashflow} id='Cashflow' onChange={(e) => handleUpdateMetricValues(e, 'Cashflow')} />
                        </div>
                        <div className='incomeConatiner'>
                            <p>Burnrate</p><input value={SelectedMetric.NewMetric.Burnrate} id='Burnrate' onChange={(e) => handleUpdateMetricValues(e, 'Burnrate')} />
                        </div>
                        <div className='btnSection'>
                            <button className='saveBtn' onClick={updateMetricClicked}>Update Metric</button>
                        </div>
                    </div> : null}
            </div>
        );
    }
});

export default Metrics;